'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Card, CardTitle, CardDescription, CardHeader, CardContent } from '@/components/ui/card';

const TypingText = () => {
  const words = ['Anyone', 'Collectors', 'Traders', 'Investors'];
  const [wordIndex, setWordIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (letterIndex === words[wordIndex].length) {
      setTimeout(() => setDeleting(true), 1300);
    } else {
      setTimeout(() => setLetterIndex(letterIndex + 1), 75);
    }
  }, [letterIndex, wordIndex]);

  useEffect(() => {
    if (deleting && letterIndex === 0) {
      setWordIndex((wordIndex + 1) % words.length);
      setDeleting(false);
    } else if (deleting) {
      setTimeout(() => setLetterIndex(letterIndex - 1), 75);
    }
  }, [deleting, letterIndex, wordIndex]);

  return (
    <span className="relative">
      <span className="relative z-10">
        {words[wordIndex].slice(0, letterIndex)}
      </span>
      <span className="absolute bottom-0 left-0 w-full h-[4px] bg-gradient-to-r from-blue-500 to-purple-600 rounded-full transform translate-y-1"></span>
    </span>
  );
};

export function HeroBetween() {
  const features = [
    {
      icon: '⚡',
      title: 'AI + Real-time Data',
      description:
        'Advanced market analytics using AI to predict trends and identify opportunities with TradingView integration.',
    },
    {
      icon: '🔔',
      title: 'Smart Notifications',
      description:
        'Stay ahead with real-time alerts on price changes, new listings, and market opportunities tailored to your collection.',
    },
    {
      icon: '🚀',
      title: 'Efficient Cross-listing',
      description:
        'List your watches across eBay, Chrono24, and Reddit simultaneously with our streamlined multi-platform tool.',
    },
    {
      icon: '💎',
      title: 'Curated Marketplace',
      description:
        'Browse authenticated vintage and modern watches with transparent pricing and detailed histories in our trusted marketplace.',
    },
  ];

  return (
    <main className="w-full min-h-screen bg-gradient-to-b from-slate-950 via-[#0A1A40] to-slate-950 relative overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.15),transparent_60%)]" />
        <div className="absolute top-0 left-0 w-full h-full bg-[url('/stacked-waves-haikei (4).svg')] bg-cover bg-center opacity-10"></div>
      </div>
      
      <div
        className="absolute inset-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{
          background:
            'radial-gradient(circle at center, rgba(30, 144, 255, 0.2), transparent 70%)',
          filter: 'blur(100px)',
          animation: 'pulse 8s cubic-bezier(0.4, 0, 0.6, 1) infinite',
          width: '1000px',
          height: '1000px',
        }}
      />

      <section className="container mx-auto px-4 py-24 relative z-10">
        {/* Hero Title Section */}
        <div className="text-center mb-20">
          <div className="relative inline-block">
            <Image
              src="/fuzz.svg"
              alt="Fuzz"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-40 animate-pulse"
              height={300}
              width={300}
            />
            <div className="relative z-10 space-y-6">
              <h1 className="text-5xl md:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-300 leading-tight">
                Free Tracking Software
              </h1>
              <h1 className="text-5xl md:text-7xl font-bold text-white">
                For{' '}
                <span className="text-blue-400">
                  <TypingText />
                </span>
              </h1>
              <p className="text-lg md:text-xl text-slate-400 max-w-2xl mx-auto mt-4">
                The most comprehensive watch market tracking platform with
                real-time updates, price analysis, and trading tools.
              </p>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-4 gap-6 max-w-7xl mx-auto">
          {features.map((feature, index) => (
            <Card
              key={index}
              className="group bg-slate-800/50 backdrop-blur-sm border-slate-700/50 
                shadow-xl rounded-xl overflow-hidden
                transition-all duration-300 ease-out
                hover:shadow-2xl hover:shadow-blue-500/10
                hover:scale-[1.02]
                h-full"
            >
              <CardHeader className="pb-2">
                <div className="text-3xl mb-3">{feature.icon}</div>
                <CardTitle
                  className="text-xl font-bold text-slate-200 
                  group-hover:text-blue-400 transition-colors duration-300"
                >
                  {feature.title}
                </CardTitle>
              </CardHeader>
              <CardContent className="text-slate-400">
                <p className="text-sm leading-relaxed">{feature.description}</p>
              </CardContent>
              
              {/* Bottom accent line */}
              <div
                className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-transparent via-blue-500/40 to-transparent 
                transform opacity-0 group-hover:opacity-100 transition-all duration-500"
              ></div>
            </Card>
          ))}
        </div>
        
        {/* CTA Button */}
        <div className="mt-16 text-center">
          <a 
            href="/dashboard" 
            className="inline-flex items-center px-8 py-3 rounded-xl
              bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500
              hover:shadow-lg hover:shadow-blue-500/25
              transition-all duration-300 transform hover:scale-105
              text-white font-semibold text-lg"
          >
            Start Tracking
          </a>
        </div>
      </section>
    </main>
  );
}