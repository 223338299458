import React, { useState, useRef } from 'react';
import { ArrowRight } from 'lucide-react';

interface GlowButtonProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const GlowButton: React.FC<GlowButtonProps> = ({
  className = '',
  onClick,
  children,
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isWithinBounds, setIsWithinBounds] = useState(false);
  const [lastKnownPosition, setLastKnownPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!buttonRef.current) return;

    const rect = buttonRef.current.getBoundingClientRect();
    const isInside =
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom;

    setIsWithinBounds(isInside);

    if (isInside) {
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setMousePosition({ x, y });
      setLastKnownPosition({ x, y });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsWithinBounds(false);
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      className="relative inline-flex items-center"
    >
      {/* Enhanced outer glow */}
      <div
        className="absolute inset-0 rounded-full opacity-75"
        style={{
          background:
            'linear-gradient(90deg, rgba(59, 130, 246, 0.5), rgba(37, 99, 235, 0.5))',
          filter: 'blur(20px)',
          transform: 'scale(1.1)',
        }}
      />

      {/* Dynamic glow effect with smooth transitions */}
      <div
        className="absolute inset-0 rounded-full"
        style={{
          background: `radial-gradient(circle at ${isHovered ? mousePosition.x : lastKnownPosition.x}px 50%, rgba(59, 130, 246, 0.8), rgba(37, 99, 235, 0.4), transparent 70%)`,
          filter: 'blur(15px)',
          transform: 'scale(1.05)',
          opacity: isHovered ? 0.6 : 0,
          transition: `
            opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)
          `,
        }}
      />

      {/* Secondary glow for smoother fade-out */}
      <div
        className="absolute inset-0 rounded-full"
        style={{
          background: `radial-gradient(circle at ${lastKnownPosition.x}px 50%, rgba(59, 130, 246, 0.6), rgba(37, 99, 235, 0.3), transparent 70%)`,
          filter: 'blur(15px)',
          transform: `scale(${isHovered ? 1 : 1.1})`,
          opacity: isHovered ? 0 : 0.3,
          transition: `
            opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)
          `,
        }}
      />

      {/* Inner glow and glass effect */}
      <div
        className="absolute inset-0 rounded-full bg-gradient-to-r from-white/90 via-white/95 to-white/90 backdrop-blur-sm"
        style={{
          transition: 'opacity 0.4s ease-out',
        }}
      />

      {/* Button content */}
      <button
        ref={buttonRef}
        onClick={onClick}
        className={`relative flex items-center justify-center h-10 sm:pl-[59px] sm:pr-[52px] text-xs tracking-tight rounded-full border border-white/40 space-x-2 uppercase font-bold bg-white/5 backdrop-blur-sm ${className}`}
      >
        <span className="text-blue-600 transition-colors duration-200">
          {children || 'Try it Free'}
        </span>
        <ArrowRight className="w-4 h-4 text-blue-600 transition-colors duration-200" />
      </button>

      {/* Enhanced border glow */}
      <div
        className="absolute inset-0 rounded-full pointer-events-none"
        style={{
          border: '1px solid rgba(255, 255, 255, 0.8)',
          boxShadow: `
            0 0 10px rgba(59, 130, 246, ${isHovered ? 0.4 : 0.3}),
            0 0 20px rgba(59, 130, 246, ${isHovered ? 0.2 : 0.1}),
            inset 0 0 15px rgba(59, 130, 246, ${isHovered ? 0.3 : 0.2})
          `,
          transition: 'box-shadow 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      />
    </div>
  );
};

export default GlowButton;
