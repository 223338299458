'use client';

import { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { motion, Variants } from 'framer-motion';

export function HeroBottom() {
  const [isButtonHovered, setButtonHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Animation variants
  const fadeInUp: Variants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const containerVariants: Variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  // Check if element is in viewport for animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const section = document.getElementById('hero-bottom-section');
    if (section) observer.observe(section);

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  return (
    <main className="min-h-screen bg-slate-900 relative overflow-hidden">
      {/* Background elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-slate-950 to-slate-900" />
      <div
        className="absolute inset-0 opacity-20"
        style={{
          backgroundImage: `url('/market-movements.svg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div
        className="absolute inset-0 opacity-10"
        style={{
          backgroundImage:
            'radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)',
          backgroundSize: '20px 20px',
        }}
      />

      <section
        id="hero-bottom-section"
        className="container mx-auto px-4 py-20 md:py-28 relative z-10"
      >
        <motion.div
          className="text-center"
          variants={containerVariants}
          initial="initial"
          animate={isVisible ? 'animate' : 'initial'}
        >
          <motion.div variants={fadeInUp} className="mb-16 relative">
            <div className="absolute -inset-1 bg-blue-500/20 blur-xl opacity-50 rounded-full"></div>
            <h1 className="text-4xl md:text-7xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-slate-300 tracking-tight relative z-10">
              Market Movements
              <br />
              At Every Tick <span className="text-blue-400">.</span>
            </h1>
            <motion.p
              className="max-w-2xl mx-auto mt-6 text-slate-400 text-lg md:text-xl"
              variants={fadeInUp}
            >
              Track real-time price movements and discover the perfect timepiece
              in our luxury watch marketplace.
            </motion.p>
          </motion.div>

          <motion.div className="flex justify-center" variants={fadeInUp}>
            <motion.a // Changed from motion.button to motion.a
              href="/dashboard" // Now href works as expected
              className="group relative overflow-hidden inline-flex items-center px-8 py-4 rounded-xl
                bg-slate-800/50 backdrop-blur-sm border border-slate-700/50
                hover:shadow-lg hover:shadow-blue-500/20
                transition-all duration-300 transform hover:scale-105"
              whileHover={{
                boxShadow: '0 10px 25px -5px rgba(59, 130, 246, 0.3)',
              }}
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600/80 to-blue-400/80 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <span className="text-white font-semibold text-lg relative z-10">
                Get Started
              </span>
              <ChevronRight
                className="ml-2 h-5 w-5 text-blue-400 group-hover:text-white
                transform group-hover:translate-x-1 transition-all duration-300 relative z-10"
              />
            </motion.a>
          </motion.div>
        </motion.div>
      </section>
    </main>
  );
}
