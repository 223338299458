'use client';

import { useState, useEffect } from 'react';
import { MarketPreview } from '@/components/component/market-preview';
import { motion } from 'framer-motion';

export function MarketplacePreview() {
  const [isVisible, setIsVisible] = useState(false);

  // Animation variants
  const fadeInUp = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const containerVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  // Check if element is in viewport for animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const section = document.getElementById('marketplace-preview-section');
    if (section) observer.observe(section);

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  return (
    <motion.main
      className="w-full relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      {/* Background gradients */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-slate-950 to-slate-900 -z-10" />
      <div
        className="absolute inset-0 opacity-20 -z-5"
        style={{
          backgroundImage:
            'radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)',
          backgroundSize: '20px 20px',
        }}
      />

      <section
        id="marketplace-preview-section"
        className="min-h-screen flex w-full justify-center py-16 md:py-24"
      >
        <motion.div
          className="w-full max-w-7xl mx-auto px-4 md:px-8"
          variants={containerVariants}
          initial="initial"
          animate={isVisible ? 'animate' : 'initial'}
        >
          <motion.div
            className="mb-16 text-center relative"
            variants={fadeInUp}
          >
            <div className="absolute -inset-1 bg-blue-500/20 blur-xl opacity-50 rounded-full"></div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6 relative z-10">
              Discover Our Marketplace
            </h2>
            <p className="text-slate-400 text-lg md:text-xl max-w-3xl mx-auto">
              Explore our curated collection of luxury timepieces from trusted
              dealers worldwide
            </p>
          </motion.div>

          <motion.div variants={fadeInUp} className="w-full">
            <MarketPreview />
          </motion.div>
        </motion.div>
      </section>
    </motion.main>
  );
}
