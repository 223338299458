// This adapter fixes the type compatibility issues between SimpleDatafeed and HeroSearch
import { SimpleDatafeed } from '../dashboard/tradingview-utils/SimpleDatafeed';
import { SearchResult } from '../dashboard/watchlist/watchlist.types';
import { SearchSymbolResultItem } from '@/public/charting_library-master/charting_library/charting_library';

// Define the adapter interface compatible with HeroSearch
export interface DatafeedAdapter {
  searchSymbols: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: (results: SearchResult[]) => void
  ) => void;
}

// Create an adapter class that wraps SimpleDatafeed
export class SimpleDatafeedAdapter implements DatafeedAdapter {
  private datafeed: SimpleDatafeed;

  constructor() {
    this.datafeed = new SimpleDatafeed();
  }

  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: (results: SearchResult[]) => void
  ): void {
    // Use the actual SimpleDatafeed implementation but adapt the result type
    this.datafeed.searchSymbols(
      userInput,
      exchange,
      symbolType,
      (items: SearchSymbolResultItem[]) => {
        // Convert SearchSymbolResultItem[] to SearchResult[]
        const results: SearchResult[] = items.map(item => ({
          symbol: item.symbol,
          full_name: item.ticker || item.symbol, // Using ticker as full_name since full_name doesn't exist
          description: item.description || '',
          exchange: item.exchange || '',
          type: item.type || '',
          exchange_logo: item.exchange_logo,
          subModel: (item as any).subModel,
          dataCount: (item as any).dataCount,
        }));
        
        onResult(results);
      }
    );
  }
}

// Create a factory function for easier instantiation
export function createDatafeedAdapter(): DatafeedAdapter {
  return new SimpleDatafeedAdapter();
}