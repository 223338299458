import { useEffect, useState } from 'react';
import { Card } from '@/components/ui/card';
import Link from 'next/link';
import OptimizedImage from '@/utils/OptimizedImage';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

interface Watch {
  _id: string;
  name: string;
  price: number;
  image: string;
  sold?: boolean;
}

const placeholderWatches: Watch[] = [
  {
    _id: 'placeholder1',
    name: 'Rolex Submariner Date Watch',
    price: 13950,
    image: '/placeholder.svg',
  },
  {
    _id: 'placeholder2',
    name: 'Omega Speedmaster Moonwatch Professional Watch',
    price: 6650,
    image: '/placeholder.svg',
  },
  {
    _id: 'placeholder3',
    name: 'Patek Philippe Calatrava Joaillerie Ladies Watch',
    price: 29950,
    image: '/placeholder.svg',
  },
  {
    _id: 'placeholder4',
    name: 'TAG Heuer Aquaracer Professional 300 GMT Watch',
    price: 2350,
    image: '/placeholder.svg',
  },
];

export function MarketPreview() {
  const [watches, setWatches] = useState<Watch[]>(placeholderWatches);
  const [isButtonHovered, setButtonHovered] = useState(false);

  useEffect(() => {
    async function fetchWatches() {
      try {
        const response = await fetch('/api/marketplace/listings?sort=new-old');
        const data = await response.json();
        const availableWatches = data.watches.filter(
          (watch: Watch) => !watch.sold
        );
        setWatches(availableWatches.slice(0, 4));
      } catch (error) {
        console.error('Error fetching watches:', error);
      }
    }
    fetchWatches();
  }, []);

  function truncateName(name: string, maxLength: number = 40): string {
    if (name.length <= maxLength) return name;
    return name.slice(0, maxLength - 3) + '...';
  }

  return (
    <div className="w-full">
      <div className="text-center mb-12">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="relative"
        >
          <h1 className="text-3xl md:text-5xl font-bold mb-4">
            <span className="text-white">Luxury watches</span>,{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              from a trusted watch dealer.
            </span>
          </h1>
        </motion.div>
      </div>

      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <div className="flex items-center justify-center gap-3 mb-6">
          <div className="h-px bg-gradient-to-r from-transparent via-blue-500 to-transparent w-16 md:w-24"></div>
          <h2 className="text-xl md:text-2xl font-semibold text-white uppercase tracking-wider">
            New Arrivals
          </h2>
          <div className="h-px bg-gradient-to-r from-transparent via-blue-500 to-transparent w-16 md:w-24"></div>
        </div>
      </motion.div>

      <div className="px-4 md:px-8 max-w-7xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6">
          {watches.slice(0, 4).map((watch, index) => (
            <motion.div
              key={watch._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + index * 0.1 }}
            >
              <Link href={`/marketplace/${watch._id}`}>
                <Card className="group text-center overflow-hidden rounded-xl bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 hover:border-blue-500/50 transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/20 h-full">
                  <div className="relative aspect-square w-full overflow-hidden bg-slate-900/50">
                    <OptimizedImage
                      src={watch.image || '/placeholder.svg'}
                      alt={watch.name}
                      width={400}
                      height={400}
                      className="absolute inset-0 object-contain w-full h-full p-2"
                      priority={index === 0}
                      sizes="(max-width: 768px) 45vw, 20vw"
                      quality={90}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-transparent opacity-0 group-hover:opacity-30 transition-opacity duration-300" />
                  </div>
                  <div className="p-4">
                    <div className="text-sm md:text-base font-medium text-white mb-1 truncate">
                      {truncateName(watch.name, 25)}
                    </div>
                    <div className="text-lg font-bold text-blue-400">
                      ${watch.price.toLocaleString()}
                    </div>
                  </div>
                </Card>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>

      <motion.div
        className="flex justify-center mt-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
      >
        <Link href="/marketplace">
          <motion.button
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            className="group relative overflow-hidden inline-flex items-center px-8 py-4 rounded-xl
              bg-slate-800/50 backdrop-blur-sm border border-slate-700/50
              hover:shadow-lg hover:shadow-blue-500/20
              transition-all duration-300 transform hover:scale-105"
            whileHover={{
              boxShadow: '0 10px 25px -5px rgba(59, 130, 246, 0.3)',
            }}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600/80 to-blue-400/80 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <span className="text-white font-semibold text-lg relative z-10">
              Explore Marketplace
            </span>
            <ChevronRight
              className="ml-2 h-5 w-5 text-blue-400 group-hover:text-white
              transform group-hover:translate-x-1 transition-all duration-300 relative z-10"
            />
          </motion.button>
        </Link>
      </motion.div>
    </div>
  );
}
