// home/hero-front.tsx
'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import PriceTrackingHero from '@/components/component/home/price-tracking-hero';

export function HeroFront({
  setIsSticky,
}: {
  setIsSticky: (value: boolean) => void;
}) {
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setIsSticky]);

  const handleTryItFree = () => {
    router.push('/dashboard');
  };

  return (
    <section className="relative -mt-14">
      <PriceTrackingHero onTryItFree={handleTryItFree} />
    </section>
  );
}
