import React, { useState, useEffect, memo, useRef } from 'react';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

// Global image cache
const IMAGE_CACHE: Record<string, string> = {};
const IMAGE_LOADING_CACHE: Record<string, Promise<string>> = {};
const ERROR_CACHE: Set<string> = new Set();

// Model number processing function - memoized to reduce duplicate work
const modelNumberCache: Record<string, string> = {};
const getModelNumber = (symbol: string): string => {
  if (modelNumberCache[symbol]) return modelNumberCache[symbol];

  let result: string;

  if (/^\d{5,6}(?:[A-Z]{2})?$/.test(symbol)) {
    result = symbol;
  } else if (typeof symbol === 'string' && symbol.includes(':')) {
    const [brand, model] = symbol.split(':');
    result =
      brand.toUpperCase() === 'ROLEX'
        ? model.replace('Rolex ', '').replace('ROLEX:', '').trim()
        : symbol.replace(/(?:ROLEX:|Rolex:|WatchInspect:|Rolex )/g, '').trim();
  } else if (symbol.startsWith('Rolex ')) {
    result = symbol.replace('Rolex ', '').trim();
  } else {
    result = symbol
      .replace(/(?:ROLEX:|Rolex:|WatchInspect:|Rolex )/g, '')
      .trim();
  }

  modelNumberCache[symbol] = result;
  return result;
};

const loadImage = async (modelNumber: string): Promise<string> => {
  if (IMAGE_CACHE[modelNumber]) return IMAGE_CACHE[modelNumber];
  if (ERROR_CACHE.has(modelNumber)) return '/rolex1.svg';

  const existingPromise = IMAGE_LOADING_CACHE[modelNumber];
  if (existingPromise) return existingPromise;

  const loadingPromise = (async () => {
    try {
      const paths = [`/rolex/${modelNumber}.png`, `/rolex/${modelNumber}.svg`];

      for (const path of paths) {
        const response = await fetch(path);
        if (response.ok) {
          IMAGE_CACHE[modelNumber] = path;
          return path;
        }
      }

      ERROR_CACHE.add(modelNumber);
      return '/rolex1.svg';
    } catch (error) {
      ERROR_CACHE.add(modelNumber);
      return '/rolex1.svg';
    } finally {
      delete IMAGE_LOADING_CACHE[modelNumber];
    }
  })();

  IMAGE_LOADING_CACHE[modelNumber] = loadingPromise;
  return loadingPromise;
};

interface RolexImageProps {
  modelNumber: string;
  className?: string;
  size?: number;
  priority?: boolean;
  withHover?: boolean;
}

export const RolexImage = memo(
  ({
    modelNumber,
    className = '',
    size = 40,
    priority = false,
    withHover = false,
  }: RolexImageProps) => {
    const cleanModelNumber = React.useMemo(
      () => getModelNumber(modelNumber),
      [modelNumber]
    );
    const [state, setState] = useState({
      imagePath: IMAGE_CACHE[cleanModelNumber] || '/rolex1.svg',
      isLoading:
        !IMAGE_CACHE[cleanModelNumber] && !ERROR_CACHE.has(cleanModelNumber),
      hasError: ERROR_CACHE.has(cleanModelNumber),
    });

    useEffect(() => {
      if (!cleanModelNumber || !state.isLoading) return;

      let mounted = true;

      loadImage(cleanModelNumber).then((path) => {
        if (mounted) {
          setState((prev) => ({
            ...prev,
            imagePath: path,
            isLoading: false,
            hasError: ERROR_CACHE.has(cleanModelNumber),
          }));
        }
      });

      return () => {
        mounted = false;
      };
    }, [cleanModelNumber]);

    return (
      <motion.div
        className={cn(
          'relative overflow-hidden rounded-full',
          withHover && 'hover:scale-110 transition-transform duration-200',
          className
        )}
        style={{ width: size, height: size }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <AnimatePresence mode="wait">
          {state.isLoading ? (
            <motion.div
              key="loading"
              className="absolute inset-0 bg-slate-700 animate-pulse rounded-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          ) : (
            <motion.img
              key="image"
              src={state.imagePath}
              alt={`Rolex ${cleanModelNumber}`}
              className={cn(
                'w-full h-full object-contain rounded-full',
                state.hasError && 'opacity-70'
              )}
              loading={priority ? 'eager' : 'lazy'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onError={(event) => {
                const img = event.target as HTMLImageElement;
                if (img && !ERROR_CACHE.has(cleanModelNumber)) {
                  ERROR_CACHE.add(cleanModelNumber);
                  img.src = '/rolex1.svg';
                  setState((prev) => ({ ...prev, hasError: true }));
                }
              }}
            />
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
);

RolexImage.displayName = 'RolexImage';
