'use client';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import debounce from 'lodash/debounce';
import { RolexImage } from '@/utils/RolexImage';
import {
  SearchResult,
  SearchSymbolResultItem,
  ExtendedSearchSymbolResultItem,
} from '../dashboard/watchlist/watchlist.types';

// Enhanced interface for ISimpleDatafeed
interface ISimpleDatafeed {
  searchSymbols?: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: (result: SearchResult[]) => void
  ) => void;
}

// Animation timing constants
const INITIAL_PLACEHOLDER = 'Search for a watch or model...';
const DELAY_BEFORE_START = 3000; // Initial delay before starting animation
const CLEAR_PAUSE = 200; // Pause after clearing the placeholder
const DISPLAY_DURATION = 2000; // How long to show the completed text
const RESULT_ANIMATION_DURATION = 4000; // Total time to spend on each result
const EXACT_RESULTS = 4;
const DURATION_LABEL = '30D';

// Moved to outside component - only needs to be defined once
const isWatchSymbol = (symbol: string): boolean => {
  const excludedPrefixes = [
    'INFLATION:',
    'INTEREST:',
    'INDEX:',
    'METAL:',
    'EMPLOYMENT:',
    'MACRO:',
    'FED:',
    'MONEY:',
    'ECONOMIC:',
  ];
  return !excludedPrefixes.some((prefix) =>
    symbol.toUpperCase().startsWith(prefix.toUpperCase())
  );
};

interface HeroSearchProps {
  onSymbolSelect: (symbol: SearchResult) => void;
  datafeed: ISimpleDatafeed;
}

interface TrendInfo {
  isPositive: boolean;
  changePercent: number;
  startPrice: number;
  endPrice: number;
}

interface ChartDataPoint {
  time: number;
  price: number;
}

interface PriceInfo {
  currentPrice: number;
  lastUpdated: number;
}

// Create a combined state interface to reduce state variables
interface HeroSearchState {
  searchResults: SearchResult[];
  isSearchVisible: boolean;
  inputValue: string;
  isLoading: boolean;
  previewIndex: number;
  autoScrollEnabled: boolean;
  userInteracting: boolean;
  placeholder: string;
  isTyping: boolean;
}

// Define a proper interface for SearchResultItem props
interface SearchResultItemProps {
  result: SearchResult;
  index: number;
  previewIndex: number;
  userInteracting: boolean;
  miniCharts: { [key: string]: ChartDataPoint[] };
  trends: { [key: string]: TrendInfo };
  prices: { [key: string]: PriceInfo };
  onSelect: (result: SearchResult) => void;
  getFormattedWatchName: (result: SearchResult) => string;
  getModelNumber: (symbol: string) => string;
}

// Memoized SearchResultItem component to prevent unnecessary re-renders
const SearchResultItem = React.memo(
  ({
    result,
    index,
    previewIndex,
    userInteracting,
    miniCharts,
    trends,
    prices,
    onSelect,
    getFormattedWatchName,
    getModelNumber,
  }: SearchResultItemProps) => {
    const isActive = !userInteracting && index === previewIndex;

    // Using CSS class-based animation for better performance
    return (
      <li
        className={`search-result-item p-3 hover:bg-slate-700/30 cursor-pointer transition-all duration-300 relative ${
          isActive ? 'search-result-active' : ''
        }`}
        onClick={() => onSelect(result)}
        style={{
          transform: isActive ? 'scale(1.02)' : 'scale(0.98)',
          opacity: isActive ? 1 : 0.8,
          transition: 'transform 0.3s ease, opacity 0.3s ease',
        }}
      >
        <div className="absolute top-2 left-2 bg-slate-900 text-slate-300 text-[10px] px-1 rounded-sm opacity-70">
          {DURATION_LABEL}
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-3 flex-grow">
            <div className="flex-shrink-0">
              <RolexImage
                modelNumber={getModelNumber(result.symbol)}
                size={40}
                className="rounded-full transition-transform duration-300 hover:scale-110"
              />
            </div>
            <div className="min-w-0">
              <div className="font-medium text-sm text-slate-200 break-words">
                {getFormattedWatchName(result)}
              </div>
              <div className="text-xs text-slate-400 flex items-center gap-2">
                <span>{result.exchange}</span>
                {trends[result.symbol] && (
                  <span
                    className={`flex items-center gap-1 font-medium ${
                      trends[result.symbol].isPositive
                        ? 'text-emerald-400'
                        : 'text-rose-400'
                    }`}
                  >
                    {trends[result.symbol].isPositive ? (
                      <ArrowUpCircle className="w-3 h-3" />
                    ) : (
                      <ArrowDownCircle className="w-3 h-3" />
                    )}
                    {Math.abs(trends[result.symbol].changePercent).toFixed(1)}%
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end gap-1">
            {miniCharts[result.symbol] && (
              <div className="w-24 h-12">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={miniCharts[result.symbol]}>
                    <defs>
                      <linearGradient
                        id={`gradient-${result.symbol}`}
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="0"
                      >
                        <stop offset="0%" stopColor="#3b82f6" />
                        <stop
                          offset="100%"
                          stopColor={
                            trends[result.symbol]?.isPositive
                              ? '#34d399'
                              : '#fb7185'
                          }
                        />
                      </linearGradient>
                    </defs>
                    <Line
                      type="monotone"
                      dataKey="price"
                      stroke={`url(#gradient-${result.symbol})`}
                      dot={false}
                      strokeWidth={1.5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            <div className="text-sm font-medium text-slate-200">
              {prices[result.symbol] &&
              !isNaN(prices[result.symbol].currentPrice) ? (
                `$${prices[result.symbol].currentPrice.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                  }
                )}`
              ) : (
                <div className="loading-price text-xs text-slate-400">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }
);

// Add display name to satisfy React warning
SearchResultItem.displayName = 'SearchResultItem';

// Extracted data fetching to a custom hook
const useWatchData = (datafeed: ISimpleDatafeed) => {
  const [miniCharts, setMiniCharts] = useState<{
    [key: string]: ChartDataPoint[];
  }>({});
  const [trends, setTrends] = useState<{ [key: string]: TrendInfo }>({});
  const [prices, setPrices] = useState<{ [key: string]: PriceInfo }>({});

  const calculateTrend = useCallback((data: ChartDataPoint[]): TrendInfo => {
    if (data.length < 2) {
      return { isPositive: true, changePercent: 0, startPrice: 0, endPrice: 0 };
    }
    const startPrice = data[0].price;
    const endPrice = data[data.length - 1].price;
    const changePercent = ((endPrice - startPrice) / startPrice) * 100;
    return {
      isPositive: changePercent >= 0,
      changePercent,
      startPrice,
      endPrice,
    };
  }, []);

  const fetchRecentPriceDataForResults = useCallback(
    async (watches: SearchResult[]) => {
      if (!watches.length) return;

      const chartData: { [key: string]: ChartDataPoint[] } = {};
      const trendData: { [key: string]: TrendInfo } = {};
      const pricesData: { [key: string]: PriceInfo } = {};

      const now = Date.now();
      const last30DaysTimestamp = now - 30 * 24 * 60 * 60 * 1000;
      const toTimestamp = Math.floor(now / 1000);
      const fromTimestamp = Math.floor(last30DaysTimestamp / 1000);

      // Use Promise.all to fetch data in parallel
      const fetchPromises = watches.map(async (watch) => {
        try {
          const response = await fetch(
            `/api/trading-dashboard/getBars?symbol=${watch.symbol}&from=${fromTimestamp}&to=${toTimestamp}&simple=true&source=forum`
          );

          if (!response.ok) {
            console.error(
              `Failed to fetch recent price data for ${watch.symbol}: ${response.statusText}`
            );
            return null;
          }

          const data = await response.json();

          if (!Array.isArray(data) || data.length === 0) {
            console.warn(`No recent price data available for ${watch.symbol}`);
            return null;
          }

          const validPoints: ChartDataPoint[] = [];
          for (const item of data) {
            let time = item.t || item.time;
            let price: number | null = null;

            if (typeof item.p === 'number') price = item.p;
            else if (typeof item.close === 'number') price = item.close;
            else if (typeof item.p === 'string') price = parseFloat(item.p);
            else if (typeof item.close === 'string')
              price = parseFloat(item.close);
            else if (typeof item.open === 'number') price = item.open;

            if (time && price !== null && !isNaN(price) && price > 0) {
              validPoints.push({ time: time * 1000, price });
            }
          }

          if (validPoints.length) {
            chartData[watch.symbol] = validPoints;
            trendData[watch.symbol] = calculateTrend(validPoints);
            pricesData[watch.symbol] = {
              currentPrice: validPoints[validPoints.length - 1].price,
              lastUpdated: validPoints[validPoints.length - 1].time,
            };
          }
        } catch (error) {
          console.error(
            `Error fetching recent price data for ${watch.symbol}:`,
            error
          );
        }
      });

      await Promise.all(fetchPromises);

      setMiniCharts((prev) => ({ ...prev, ...chartData }));
      setTrends((prev) => ({ ...prev, ...trendData }));
      setPrices((prev) => ({ ...prev, ...pricesData }));
    },
    [calculateTrend]
  );

  const fetchWatchData = useCallback(async () => {
    try {
      const response = await fetch('/api/trading-dashboard/watchData');
      const responseJson = await response.json();
      const data = responseJson.data;

      if (!Array.isArray(data)) {
        console.error('Expected data to be an array but received:', data);
        return [];
      }

      const watchOnlyData = data.filter((item) => isWatchSymbol(item.symbol));
      const uniqueData = Array.from(
        new Map(watchOnlyData.map((item) => [item.symbol, item])).values()
      );

      const sortedModels = uniqueData
        .sort((a, b) => (b.dataCount || 0) - (a.dataCount || 0))
        .slice(0, 30);

      const previewWatches: SearchResult[] = [];
      const availableModels = [...sortedModels];

      while (
        previewWatches.length < EXACT_RESULTS &&
        availableModels.length > 0
      ) {
        const randomIndex = Math.floor(Math.random() * availableModels.length);
        const selected = availableModels[randomIndex];

        if (!previewWatches.some((watch) => watch.symbol === selected.symbol)) {
          previewWatches.push(selected);
        }
        availableModels.splice(randomIndex, 1);
      }

      await fetchRecentPriceDataForResults(previewWatches);
      return previewWatches;
    } catch (error) {
      console.error('Error fetching watch data:', error);
      return [];
    }
  }, [fetchRecentPriceDataForResults]);

  const fetchPriceHistory = useCallback(
    async (symbol: string): Promise<ChartDataPoint[]> => {
      try {
        const rangeResponse = await fetch(
          `/api/trading-dashboard/getDataRange?symbol=${symbol}&source=forum`
        );

        if (!rangeResponse.ok) return [];

        const range = await rangeResponse.json();
        if (!range.firstDataPoint || !range.lastDataPoint) return [];

        const response = await fetch(
          `/api/trading-dashboard/getBars?symbol=${symbol}&from=${range.firstDataPoint}&to=${range.lastDataPoint}&source=forum`
        );

        if (!response.ok) return [];

        const data = await response.json();
        if (!Array.isArray(data) || data.length === 0) return [];

        const validPoints: ChartDataPoint[] = [];
        for (const item of data) {
          let time = item.t || item.time;
          let price: number | null = null;

          if (typeof item.p === 'number') price = item.p;
          else if (typeof item.close === 'number') price = item.close;
          else if (typeof item.p === 'string') price = parseFloat(item.p);
          else if (typeof item.close === 'string')
            price = parseFloat(item.close);
          else if (typeof item.open === 'number') price = item.open;

          if (time && price !== null && !isNaN(price) && price > 0) {
            validPoints.push({ time, price });
          }
        }

        const recentPoints = validPoints
          .sort((a, b) => b.time - a.time)
          .slice(0, 30)
          .reverse();

        return recentPoints;
      } catch (error) {
        console.error(`Error fetching price history for ${symbol}:`, error);
        return [];
      }
    },
    []
  );

  return {
    miniCharts,
    trends,
    prices,
    fetchWatchData,
    fetchPriceHistory,
    fetchRecentPriceDataForResults,
  };
};

const HeroSearch: React.FC<HeroSearchProps> = ({
  onSymbolSelect,
  datafeed,
}) => {
  // Use a ref for animation state to avoid re-renders
  const animationRef = useRef({
    timeoutId: null as NodeJS.Timeout | null,
    animationActive: false,
    interactionTimer: null as NodeJS.Timeout | null,
    animationFrameId: null as number | null,
    animationStart: 0,
    typingTimeout: null as NodeJS.Timeout | null,
  });

  // Combined state to reduce re-renders
  const [state, setState] = useState<HeroSearchState>({
    searchResults: [],
    isSearchVisible: false,
    inputValue: '',
    isLoading: false,
    previewIndex: 0,
    autoScrollEnabled: true,
    userInteracting: false,
    placeholder: INITIAL_PLACEHOLDER,
    isTyping: false,
  });

  // Use a ref for placeholder value to update without re-renders
  const placeholderRef = useRef(INITIAL_PLACEHOLDER);

  // Ref for the input element to programmatically change placeholder directly
  const inputRef = useRef<HTMLInputElement>(null);

  // Use custom hook for data fetching
  const {
    miniCharts,
    trends,
    prices,
    fetchWatchData,
    fetchPriceHistory,
    fetchRecentPriceDataForResults,
  } = useWatchData(datafeed);

  // Memoize utility functions to prevent recreation on each render
  const getModelNumber = useCallback(
    (symbol: string): string => symbol.split(':')[1],
    []
  );

  const getFormattedWatchName = useCallback((result: SearchResult): string => {
    const brand = result.symbol.split(':')[0];
    const model = result.symbol.split(':')[1];
    return result.subModel
      ? `${brand} ${result.subModel} ${model}`
      : `${brand} ${model}`;
  }, []);

  // New function to update placeholder without React state
  const setDirectPlaceholder = useCallback((text: string) => {
    placeholderRef.current = text;
    if (inputRef.current) {
      inputRef.current.placeholder = text;
    }
  }, []);

  // Initialize data
  useEffect(() => {
    const loadInitialData = async () => {
      const results = await fetchWatchData();
      if (results.length) {
        setState((prev) => ({
          ...prev,
          searchResults: results,
          isSearchVisible: true,
        }));
      }
    };

    loadInitialData();

    return () => {
      // Clean up all timers
      if (animationRef.current.timeoutId) {
        clearTimeout(animationRef.current.timeoutId);
      }
      if (animationRef.current.interactionTimer) {
        clearTimeout(animationRef.current.interactionTimer);
      }
      if (animationRef.current.animationFrameId) {
        cancelAnimationFrame(animationRef.current.animationFrameId);
      }
      if (animationRef.current.typingTimeout) {
        clearTimeout(animationRef.current.typingTimeout);
      }
    };
  }, [fetchWatchData]);

  // Handle user interaction - use callback to prevent recreation
  const handleUserInteraction = useCallback(() => {
    setState((prev) => ({
      ...prev,
      userInteracting: true,
      autoScrollEnabled: false,
      isTyping: false,
    }));
    setDirectPlaceholder(INITIAL_PLACEHOLDER);

    if (animationRef.current.interactionTimer) {
      clearTimeout(animationRef.current.interactionTimer);
    }

    const timer = setTimeout(() => {
      setState((prev) => ({
        ...prev,
        userInteracting: false,
        autoScrollEnabled: true,
        previewIndex: 0,
      }));
    }, 10000);

    animationRef.current.interactionTimer = timer;
  }, [setDirectPlaceholder]);

  // Synchronized typing animation that takes the appropriate amount of time
  const typeText = useCallback(
    (text: string, onComplete?: () => void) => {
      // Calculate available typing time to maintain consistent animation pace
      // Total animation time - (clear pause + display duration)
      const availableTypingTime =
        RESULT_ANIMATION_DURATION - (CLEAR_PAUSE + DISPLAY_DURATION);

      // Clear any existing animations
      if (animationRef.current.typingTimeout) {
        clearTimeout(animationRef.current.typingTimeout);
      }
      if (animationRef.current.animationFrameId) {
        cancelAnimationFrame(animationRef.current.animationFrameId);
      }

      // Clear the placeholder
      setDirectPlaceholder('');

      // Wait before starting to type
      animationRef.current.typingTimeout = setTimeout(() => {
        const startTime = performance.now();

        // Calculate typing speed based on text length and available time
        // This ensures typing always takes the same amount of time regardless of text length
        const charInterval =
          text.length > 0 ? availableTypingTime / text.length : 100;

        // Smooth typing animation using requestAnimationFrame
        const animateTyping = (timestamp: number) => {
          const elapsed = timestamp - startTime;

          // Calculate how many characters should be visible by now
          // This creates a consistent typing duration regardless of text length
          const progress = Math.min(elapsed / availableTypingTime, 1);
          const charCount = Math.floor(progress * text.length);

          // Update the placeholder text
          setDirectPlaceholder(text.substring(0, charCount));

          if (progress < 1) {
            // Continue animation
            animationRef.current.animationFrameId =
              requestAnimationFrame(animateTyping);
          } else {
            // Typing complete - show full text
            setDirectPlaceholder(text);

            // Wait for display duration before moving to next
            if (onComplete) {
              animationRef.current.typingTimeout = setTimeout(
                onComplete,
                DISPLAY_DURATION
              );
            }
          }
        };

        animationRef.current.animationFrameId =
          requestAnimationFrame(animateTyping);
      }, CLEAR_PAUSE);
    },
    [setDirectPlaceholder]
  );

  // Animations - completely refactored to use requestAnimationFrame with synchronized timing
  useEffect(() => {
    if (state.searchResults.length === 0 || state.userInteracting) {
      return;
    }

    let isActive = true;
    animationRef.current.animationActive = true;

    const runAnimation = () => {
      const startAnimation = () => {
        let currentIndex = 0;

        const animateNextItem = () => {
          if (!isActive || !animationRef.current.animationActive) return;

          // Update which item is highlighted
          setState((prev) => ({
            ...prev,
            previewIndex: currentIndex,
          }));

          // Get the text to type
          const result = state.searchResults[currentIndex];
          const text = getFormattedWatchName(result);

          // Type the text with a callback for when typing is done
          typeText(text, () => {
            if (!isActive || !animationRef.current.animationActive) return;

            // Move to next item
            currentIndex = (currentIndex + 1) % state.searchResults.length;
            animateNextItem();
          });
        };

        // Start the animation sequence
        animateNextItem();
      };

      // Delay before starting the animation
      animationRef.current.timeoutId = setTimeout(
        startAnimation,
        DELAY_BEFORE_START
      );
    };

    runAnimation();

    return () => {
      isActive = false;
      animationRef.current.animationActive = false;

      // Clear all animations
      if (animationRef.current.timeoutId) {
        clearTimeout(animationRef.current.timeoutId);
      }
      if (animationRef.current.typingTimeout) {
        clearTimeout(animationRef.current.typingTimeout);
      }
      if (animationRef.current.animationFrameId) {
        cancelAnimationFrame(animationRef.current.animationFrameId);
      }
    };
  }, [
    state.searchResults,
    state.userInteracting,
    getFormattedWatchName,
    typeText,
  ]);

  // Debounced search with useCallback + useMemo to prevent recreation
  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!datafeed || !datafeed.searchSymbols) {
          console.error('Datafeed not properly initialized');
          return;
        }

        setState((prev) => ({ ...prev, isLoading: true }));

        if (query.trim() === '') {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            previewIndex: 0,
          }));
          return;
        }

        try {
          datafeed.searchSymbols(
            query,
            '',
            '',
            async (symbols: SearchResult[]) => {
              // Filter out non-watch symbols
              const watchOnlySymbols = symbols.filter((item) =>
                isWatchSymbol(item.symbol)
              );

              // Deduplicate search results
              const uniqueSymbols = Array.from(
                new Map(
                  watchOnlySymbols.map((item) => [item.symbol, item])
                ).values()
              );

              const processedSymbols = uniqueSymbols.slice(0, EXACT_RESULTS);

              setState((prev) => ({
                ...prev,
                searchResults: processedSymbols,
                isLoading: false,
                previewIndex: 0, // Reset to first item
              }));

              await fetchRecentPriceDataForResults(processedSymbols);
            }
          );
        } catch (error) {
          console.error('Search error:', error);
          setState((prev) => ({
            ...prev,
            searchResults: [],
            isLoading: false,
          }));
        }
      }, 300),
    [datafeed, fetchRecentPriceDataForResults]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((prev) => ({ ...prev, inputValue: value }));
      handleUserInteraction();
      debouncedSearch(value);
    },
    [debouncedSearch, handleUserInteraction]
  );

  const handleSymbolSelectClick = useCallback(
    async (result: SearchResult) => {
      onSymbolSelect(result);
      setState((prev) => ({
        ...prev,
        inputValue: getFormattedWatchName(result),
        isSearchVisible: false,
      }));

      try {
        await fetchPriceHistory(result.symbol);
      } catch (error) {
        console.error(
          `Error fetching data for ${result.symbol} after selection:`,
          error
        );
      }
    },
    [onSymbolSelect, getFormattedWatchName, fetchPriceHistory]
  );

  // Memoize search results rendering to prevent rebuilding DOM on every render
  const searchResultsList = useMemo(() => {
    return state.searchResults.map((result, index) => (
      <SearchResultItem
        key={result.symbol}
        result={result}
        index={index}
        previewIndex={state.previewIndex}
        userInteracting={state.userInteracting}
        miniCharts={miniCharts}
        trends={trends}
        prices={prices}
        onSelect={handleSymbolSelectClick}
        getFormattedWatchName={getFormattedWatchName}
        getModelNumber={getModelNumber}
      />
    ));
  }, [
    state.searchResults,
    state.previewIndex,
    state.userInteracting,
    miniCharts,
    trends,
    prices,
    handleSymbolSelectClick,
    getFormattedWatchName,
    getModelNumber,
  ]);

  return (
    <div className="relative w-full md:w-96">
      <Input
        ref={inputRef}
        className="w-full bg-slate-800/50 backdrop-blur-sm border border-slate-700/50
                   rounded-xl px-4 py-2 text-sm text-slate-200
                   hover:border-blue-500/30 focus:ring-2 focus:ring-blue-500/20
                   transition-all duration-300"
        placeholder={placeholderRef.current}
        value={state.inputValue}
        onChange={handleInputChange}
        onFocus={() => {
          setState((prev) => ({ ...prev, isSearchVisible: true }));
          handleUserInteraction();
        }}
      />
      <AnimatePresence>
        {state.isSearchVisible &&
          (state.searchResults.length > 0 || state.isLoading) && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute w-full mt-2 z-50"
            >
              <Card className="bg-slate-800/90 backdrop-blur-xl border-slate-700/50 shadow-2xl rounded-xl overflow-hidden">
                {state.isLoading ? (
                  <div className="p-4 text-center text-slate-400">
                    <div className="loading-text">Searching...</div>
                  </div>
                ) : (
                  <ul className="divide-y divide-slate-700/30">
                    {searchResultsList}
                  </ul>
                )}
              </Card>
            </motion.div>
          )}
      </AnimatePresence>

      {/* CSS for animations with better performance */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .search-result-item {
          transform-origin: center;
        }

        .loading-text,
        .loading-price {
          animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
          0%,
          100% {
            opacity: 0.5;
          }
          50% {
            opacity: 1;
          }
        }
        `
        }}
      />
    </div>
  );
};

export default React.memo(HeroSearch);
