import React, { useState, useEffect } from 'react';

interface MousePosition {
  x: number;
  y: number;
}

interface HoveredCell {
  x: number;
  y: number;
}

const GridBackground: React.FC = () => {
  const [hoveredCell, setHoveredCell] = useState<HoveredCell>({ x: -1, y: -1 });
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0.5,
    y: 0.5,
  });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / 40);
    const y = Math.floor((e.clientY - rect.top) / 40);
    setHoveredCell({ x, y });
    setMousePosition({
      x: (e.clientX - rect.left) / rect.width,
      y: (e.clientY - rect.top) / rect.height,
    });
  };

  return (
    <div className="absolute inset-0 overflow-hidden">
      {/* Energetic Base Grid */}
      <div
        className="absolute inset-0 transition-all duration-2000" // Increased from 1000
        style={{
          backgroundImage: `
            linear-gradient(to right, rgba(59, 130, 246, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(59, 130, 246, 0.05) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          maskImage:
            'radial-gradient(circle at center, transparent 0%, black 100%)',
          animation: 'energeticGrid 12s ease-in-out infinite', // Increased from 6s
          transform: 'perspective(1000px) rotateX(2deg)',
        }}
      />

      {/* Dynamic Interactive Layer */}
      <div
        className="absolute inset-0"
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => {
          setHoveredCell({ x: -1, y: -1 });
          setMousePosition({ x: 0.5, y: 0.5 });
          setIsHovering(false);
        }}
      >
        {/* Reactive Grid Overlay */}
        <div
          className="absolute inset-0 transition-opacity duration-700" // Increased from 300
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(59, 130, 246, 0.15) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(59, 130, 246, 0.15) 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            opacity: hoveredCell.x >= 0 ? 1 : 0.4,
            animation: 'gridPulse 8s ease-in-out infinite', // Increased from 4s
          }}
        />

        {/* Enhanced Hover Effect */}
        {hoveredCell.x >= 0 && (
          <>
            {/* Primary Glow */}
            <div
              className="absolute w-[200px] h-[200px] pointer-events-none"
              style={{
                left: `${hoveredCell.x * 40}px`,
                top: `${hoveredCell.y * 40}px`,
                background:
                  'radial-gradient(circle, rgba(59, 130, 246, 0.3) 0%, transparent 70%)',
                transform: 'translate(-50%, -50%)',
                animation: 'energeticPulse 4s ease-in-out infinite', // Increased from 2s
              }}
            />
            {/* Secondary Glow */}
            <div
              className="absolute w-[300px] h-[300px] pointer-events-none"
              style={{
                left: `${hoveredCell.x * 40}px`,
                top: `${hoveredCell.y * 40}px`,
                background:
                  'radial-gradient(circle, rgba(59, 130, 246, 0.1) 0%, transparent 70%)',
                transform: 'translate(-50%, -50%)',
                animation: 'energeticPulseWide 6s ease-in-out infinite', // Increased from 3s
              }}
            />
          </>
        )}
      </div>
      {/* Enhanced Blobs */}
      <div className="absolute inset-0">
        {/* Modified Top Right Energetic Blob */}
        <div
          className="absolute -top-1/4 -right-1/4 w-3/4 h-3/4" // Increased size and adjusted position
          style={{
            transform: `translate(
              ${(mousePosition.x - 0.5) * -30}px,
              ${(mousePosition.y - 0.5) * -30}px
            )`,
            transition: 'transform 0.6s ease-out',
          }}
        >
          <div
            className="absolute inset-0 bg-blue-500/10 blur-[150px] rounded-[40%]" // Reduced opacity, increased blur, less circular
            style={{
              animation: 'energeticBlob 16s ease-in-out infinite',
              maskImage: `
                radial-gradient(
                  ellipse at center,
                  rgba(0, 0, 0, 0.8) 0%,
                  transparent 70%
                ),
                repeating-linear-gradient(45deg,
                  rgba(0, 0, 0, 0.7) 0px,
                  rgba(0, 0, 0, 0.7) 2px,
                  transparent 2px,
                  transparent 4px
                )
              `,
              transform: 'skew(-10deg, 5deg)', // Added skew for less circular shape
            }}
          />
        </div>

        {/* Bottom Left Energetic Blob */}
        <div
          className="absolute bottom-0 -left-1/4 w-1/2 h-1/2"
          style={{
            transform: `translate(
              ${(mousePosition.x - 0.5) * 30}px,
              ${(mousePosition.y - 0.5) * 30}px
            )`,
            transition: 'transform 0.6s ease-out', // Increased from 0.3s
          }}
        >
          <div
            className="absolute inset-0 bg-blue-500/15 blur-[100px] rounded-full"
            style={{
              animation: 'energeticBlob 16s ease-in-out infinite 0.5s', // Increased from 8s
              maskImage: `
                repeating-linear-gradient(-45deg,
                  rgba(0, 0, 0, 0.9) 0px,
                  rgba(0, 0, 0, 0.9) 2px,
                  transparent 2px,
                  transparent 4px
                )
              `,
            }}
          />
        </div>
      </div>

      {/* Dynamic Ambient Light */}
      <div
        className="absolute inset-0 opacity-50 pointer-events-none"
        style={{
          background: `radial-gradient(
            circle at ${mousePosition.x * 100}% ${mousePosition.y * 100}%,
            rgba(59, 130, 246, 0.2) 0%,
            transparent 60%
          )`,
          transition: 'all 0.6s ease-out', // Increased from 0.3s
        }}
      />

      <style>
        {`
        @keyframes energeticGrid {
          0%,
          100% {
            opacity: 0.3;
            transform: perspective(1000px) rotateX(2deg) translateY(0px);
          }
          50% {
            opacity: 0.6;
            transform: perspective(1000px) rotateX(2deg) translateY(-10px);
          }
        }

        @keyframes gridPulse {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.02);
          }
        }

        @keyframes energeticBlob {
          0%,
          100% {
            transform: scale(1) translate(0, 0) rotate(0deg) skew(-10deg, 5deg);
            opacity: 0.4;
          }
          25% {
            transform: scale(1.3) translate(2%, 2%) rotate(3deg)
              skew(-12deg, 3deg);
            opacity: 0.6;
          }
          75% {
            transform: scale(1.2) translate(-2%, -2%) rotate(-3deg)
              skew(-8deg, 7deg);
            opacity: 0.5;
          }
        }
        @keyframes energeticPulse {
          0%,
          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0.3;
          }
          50% {
            transform: translate(-50%, -50%) scale(1.2);
            opacity: 0.5;
          }
        }

        @keyframes energeticPulseWide {
          0%,
          100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0.1;
          }
          50% {
            transform: translate(-50%, -50%) scale(1.3);
            opacity: 0.2;
          }
        }
        `}
      </style>
    </div>
  );
};

export default GridBackground;
