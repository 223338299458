'use client';

import React, { useMemo } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import Link from 'next/link';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  ArrowRight,
  LineChart as LineChartIcon,
  ShieldAlert,
  BarChart4,
} from 'lucide-react';

interface SimpleData {
  t: number;
  p: number;
}

interface ChartDataPoint {
  name: string;
  price: number;
}

export function HeroMid() {
  // Generate more realistic price data with trends and volatility
  const generateChartData = (): ChartDataPoint[] => {
    const data: ChartDataPoint[] = [];
    let price = 15000;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    for (let i = 0; i < 12; i++) {
      // Add some volatility and general upward trend
      const volatility = Math.random() * 0.05 - 0.02;
      const trend = 0.01;
      price = price * (1 + volatility + trend);

      data.push({
        name: months[i],
        price: Math.round(price),
      });
    }

    return data;
  };

  const chartData = useMemo(() => generateChartData(), []);
  const currentPrice = useMemo(() => {
    return chartData[chartData.length - 1].price.toLocaleString();
  }, [chartData]);

  return (
    <main className="relative bg-gradient-to-b from-slate-950 to-blue-950 text-white py-24">
      <div className="container mx-auto px-4">
        <Card className="bg-slate-900/70 backdrop-blur-xl border border-white/10 rounded-2xl overflow-hidden shadow-xl">
          <CardContent className="p-8">
            <div className="grid lg:grid-cols-2 gap-12">
              {/* Left Column */}
              <div className="space-y-6">
                <div className="space-y-4">
                  <Badge className="bg-blue-600 text-white px-3 py-1 rounded-full font-medium border-0">
                    WATCH TRACKER
                  </Badge>
                  <h1 className="text-5xl font-bold text-white leading-tight">
                    Chart. Track.
                    <br />
                    <span className="text-blue-400">Notify.</span>
                  </h1>
                </div>

                <p className="text-lg text-slate-300">
                  Get real-time price alerts for your favorite watches. Built by
                  enthusiasts, for enthusiasts.
                </p>

                {/* Chart Container */}
                <div className="bg-slate-800/60 rounded-xl overflow-hidden border border-slate-700/50 p-5 h-64">
                  <div className="mb-3 flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-medium text-white">
                        Submariner Price Trend
                      </h3>
                      <p className="text-slate-400 text-sm">
                        12-month performance
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-lg font-bold text-white">
                        ${currentPrice}
                      </p>
                      <p className="text-green-400 text-sm">+12.4% YTD</p>
                    </div>
                  </div>

                  <div className="h-44">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={chartData}
                        margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#334155" />
                        <XAxis
                          dataKey="name"
                          tick={{ fill: '#94a3b8' }}
                          axisLine={{ stroke: '#475569' }}
                          tickLine={{ stroke: '#475569' }}
                        />
                        <YAxis
                          tick={{ fill: '#94a3b8' }}
                          axisLine={{ stroke: '#475569' }}
                          tickLine={{ stroke: '#475569' }}
                          tickFormatter={(value) => `$${value / 1000}k`}
                        />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: '#1e293b',
                            border: 'none',
                            borderRadius: '8px',
                            color: '#f8fafc',
                          }}
                          formatter={(value) => [
                            `$${value.toLocaleString()}`,
                            'Price',
                          ]}
                        />
                        <Line
                          type="monotone"
                          dataKey="price"
                          stroke="#3b82f6"
                          strokeWidth={2.5}
                          dot={false}
                          activeDot={{ r: 6, fill: '#3b82f6' }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <Link href="/portfolio" className="inline-block">
                  <Button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg transition-all duration-300 flex items-center space-x-2 text-base">
                    <span>Start Tracking</span>
                    <ArrowRight className="h-5 w-5" />
                  </Button>
                </Link>
              </div>

              {/* Right Column */}
              <div className="space-y-5">
                <h2 className="text-2xl font-semibold text-white">
                  Portfolio features
                </h2>
                <p className="text-slate-400">
                  Track your watch collection with powerful tools designed for
                  collectors and investors.
                </p>

                <div className="grid gap-5 mt-5">
                  {[
                    {
                      icon: LineChartIcon,
                      title: 'Advanced Charts',
                      description:
                        'Interactive price charts with historical data and trend analysis tools.',
                    },
                    {
                      icon: ShieldAlert,
                      title: 'Price Alerts',
                      description:
                        'Set custom alerts to notify you when watches hit your target prices.',
                    },
                    {
                      icon: BarChart4,
                      title: 'Portfolio Analytics',
                      description:
                        'Track performance metrics and value changes across your collection.',
                    },
                  ].map((feature, index) => (
                    <div
                      key={index}
                      className="p-5 rounded-xl bg-slate-800/60 border border-slate-700/50 hover:border-blue-500/30 hover:bg-slate-800/80 transition-all duration-300"
                    >
                      <div className="flex items-start gap-4">
                        <div className="p-2 bg-blue-600/10 rounded-lg">
                          <feature.icon className="h-6 w-6 text-blue-400" />
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold text-white mb-1">
                            {feature.title}
                          </h3>
                          <p className="text-slate-400">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mt-6 p-4 bg-blue-900/20 rounded-lg border border-blue-500/20">
                  <p className="text-sm text-blue-100">
                    &quot;The portfolio dashboard helped me track my collection&apos;s
                    value in real-time. Highly recommended for any watch
                    collector.&quot;
                  </p>
                  <p className="text-xs text-blue-300 mt-2">
                    — John D., Rolex Collector
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </main>
  );
}

export default HeroMid;
